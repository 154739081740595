
// import { useLockBodyScroll } from "@uidotdev/usehooks";
import { state } from "@state/store";
import { useSnapshot } from "valtio";
import { Icon } from "@iconify/react";


export default function GlobalLoader() {
    // useLockBodyScroll();
    const snap = useSnapshot(state);

    return <div className="fixed top-0 left-0 flex flex-col !z-[2147483647] justify-center items-center bg-primary/80 w-screen overflow-hidden h-screen">
        <Icon icon="svg-spinners:blocks-shuffle-3" className="text-teal-600 animate-bounce" width={200} height={200} />
        <span className="text-teal-600 text-lg mt-4 font-semibold animate-pulse">{snap.processing_text}</span>
    </div>
}
